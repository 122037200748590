import React, { useState } from 'react'
import { useObserver } from 'mobx-react'
import { useStores } from '../../stores/RootStore'
import { NavLink as RNavLink, useRouteMatch } from 'react-router-dom'
import { Collapse, Nav, NavLink, NavItem, Badge } from 'reactstrap'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import {
  FaTools,
  FaCubes as Products,
  FaFileImport as BulkUploads,
  FaFileCsv as Bulk,
  FaTable as BulkResults,
  FaFileExport as Export,
  FaClipboardList as Orders,
  FaStickyNote as PackingSlip,
  FaClipboardList as PickList,
  FaTruckLoading as UploadTracking,
  FaUserClock as Awaiting,
  FaCaretRight as DefaultIcon,
  FaCog as Settings,
  FaPlus as Plus,
  FaList as List,
  FaShippingFast as Shipping,
  FaChevronDown as DownArrow,
  FaChevronRight as RightArrow,
  // FaTerminal as Playground,
  FaHome as Dashboard,
  FaMask as Admin,
  FaUsersCog as ManageUsers,
  FaBan as DeletedUsers,
  FaUserAlt as User,
  FaBuilding as Organization,
  FaLayerGroup as ManageOrganizations,
  FaGlobe as Storefront,
  FaRegEdit as CustomFields,
  FaRegFileAlt as Templates,
  FaRegImages as Media,
  FaRegCreditCard as Billing,
  FaTasks as Logs,
  FaFileContract as Reports,
  FaRegFile as Pages,
  FaGripHorizontal as Categories,
  FaTags as Repricer,
  FaSearch as Magnifier,
  FaExclamationTriangle as ErrorsIcon,
  FaMoneyBillAlt as TaxReportIcon
  // FaRegBell as Bell – Uncomment when needed
} from 'react-icons/fa'

import { AutomationSidebar } from '../../scenes/Automation'
import { AVAILABLE_CHANNELS, ChannelSidebar, formatSlug, getAuthorizedChannels } from '../../scenes/Channel'

import './Sidebar.css'
import { UserDropdownMenuMobile } from '../UserDropdownMenu'
import { defaultLogsSearch } from '../../scenes/Logs'
import { FitmentSidebar } from '../../scenes/Fitment'

const debug = require('debug')('sd:Sidebar')

/** GraphQL query that get the storefront status */
const GET_STOREFRONT = gql`
query GetStorefrontStatus {
  StorefrontStatus {
    site
   }
}
`

export const BetaBadge = () => <Badge pill color='primary' style={{ fontSize: '60%', verticalAlign: 'super' }}>beta</Badge>

function SidebarNavLink ({
  title = '',
  to = '',
  hard = false,
  exact = false,
  icon = <DefaultIcon />,
  hideLink = false,
  childTracker,
  onClick = () => { }
}) {
  const trackChildEvent = () => {
    if (childTracker && window.analytics) {
      const currentEvent = childTracker.eventName
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        ...childTracker.data,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }

  const linkParams = hard
    ? {
        onClick:
        e => {
          trackChildEvent()
          // e.preventDefault()
          window.location = to
          if (to !== window.location.pathname) window.location.reload()
        },
        tag: 'a',
        href: to
      }
    : {
        onClick: () => {
          trackChildEvent()
          onClick()
        },
        tag: RNavLink,
        exact,
        to
      }

  return (
    <NavItem className={`menu-item ${hideLink ? 'd-none' : ''}`} key={title + to}>
      <NavLink
        className='menu-link'
        {...linkParams}
      >
        {icon} {title}
      </NavLink>
    </NavItem>
  )
}

export function SidebarSection ({
  title = '',
  to = '',
  exact = false,
  icon = <DefaultIcon />,
  navLinks,
  onClick = () => { },
  scopes = [],
  parentTracker
}) {
  const match = useRouteMatch(to)
  const [open, setOpen] = useState(to ? match : false)
  const { stores: { auth } } = useStores()
  const { user: authUser } = auth || {}

  const trackParentEvent = () => {
    if (parentTracker && window.analytics) {
      const currentEvent = parentTracker.eventName
      const currentHistory = window?.analyticsEventHistory
      const newHistory = [currentEvent, ...currentHistory].slice(0, 10)
      window.analyticsEventHistory = newHistory
      const fullFilled = {
        ...parentTracker.data,
        pathHistory: window?.analyticsPathHistory,
        priorPath: window?.analyticsPathHistory?.[0],
        eventHistory: newHistory,
        priorEvent: newHistory?.[1]
      }
      window.analytics.track(currentEvent, fullFilled)
    }
  }

  const linkParams = navLinks
    ? {
        onClick:
        e => {
          trackParentEvent()
          e.preventDefault()
          // window.location = to
          // window.location.reload()
        },
        tag: 'a',
        href: to
      }
    : {
        onClick: () => trackParentEvent(),
        tag: RNavLink,
        exact,
        to
      }
  debug('match', match)
  return useObserver(() =>
    (scopes && scopes.length > 0 && !auth.permissions.hasAnyScopeInOrganization(scopes, authUser.organizationId))
      ? null
      : (
        <nav
          role='tablist'
          id='v-pills-tab'
          aria-orientation='vertical'
          className='nav flex-column nav-pills xmy-2'
          data-intercom-target={`nav-${(title?.props?.children?.[0]?.trim() || title)?.toLowerCase()}`}
        >
          <li className='pb-1 nav-item sidebar-section' onClick={e => setOpen(!open)}>
            <NavLink {...linkParams} active={exact ? (match || {}).isExact : !!match} className='sidebar-section d-flex justify-content-between'>
              <nobr> {icon} {title} </nobr>
              {navLinks
                ? (
                    open
                      ? <DownArrow style={{ marginTop: '4px', padding: '2px' }} />
                      : <RightArrow style={{ marginTop: '4px', padding: '2px' }} />
                  )
                : null}
            </NavLink>
          </li>
          {navLinks
            ? (
              <Collapse isOpen={!!open}>
                <Nav vertical pills className='small ml-3 pb-2'>
                  {navLinks
                    .filter(({ scopes }) => (scopes && scopes.length > 0) ? auth.permissions.hasAnyScope(scopes) : true)
                    .filter(({ roles }) => (roles && roles.length > 0) ? auth.permissions.hasAnyRole(roles) : true)
                    .map((props, index) =>
                      <SidebarNavLink key={index} {...props} onClick={onClick} />
                    )}
                </Nav>
              </Collapse>
              )
            : null}
        </nav>)
  )
}

function SidebarNav ({ onClick, storefrontEnabled, authorizedChannels }) {
  const { stores: { auth } } = useStores()
  const { user: { organizationId } } = auth || {}
  const firstAuthorizedChannel = authorizedChannels?.[0]
  const { instance, channel } = firstAuthorizedChannel || {}

  const canViewOrgSettings = auth.permissions.hasScope('organization:settings:ui:read')
  const canViewMembers = auth.permissions.hasScope('organization:users:read')
  const canViewFitment = auth.permissions.hasScopeInOrganization('organization:fitment:read', organizationId)
  const canViewReports = auth.permissions.hasScope('organization:reports:read')

  return useObserver(() =>
    <div className='mb-4'>
      <SidebarSection
        title='Dashboard'
        to='/dashboard'
        onClick={onClick}
        exact
        icon={<Dashboard />}
      />
      <SidebarSection
        title='Search'
        to='/search-builder'
        onClick={onClick}
        icon={<Magnifier />}
        parentTracker={{ eventName: 'Search builder: visit', data: { action: 'User clicks Search at the sidebar' } }}
      />
      <SidebarSection
        title='Products'
        to='/#!/products'
        onClick={onClick}
        icon={<Products />}
        scopes={['organization:editor:ui:read']}
        navLinks={[
          { title: 'New Product', icon: <Plus />, hard: true, to: '/#!/products/add' },
          { title: 'All Products', icon: <List />, hard: true, to: '/#!/products' }
        ]}
      />
      <SidebarSection
        title='Orders'
        to='/#!/orders'
        onClick={onClick}
        icon={<Orders />}
        navLinks={[
          {
            title: 'New Order',
            icon: <Plus />,
            hard: true,
            to: '/#!/orders/add',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          },
          {
            title: 'All Orders',
            icon: <List />,
            hard: true,
            to: '/#!/orders',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          },
          {
            title: 'Awaiting Orders',
            icon: <Awaiting />,
            hard: true,
            to: '/#!/orders/awaiting',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          },
          {
            title: 'Packing Slips',
            icon: <PackingSlip />,
            hard: true,
            to: '/orders?do=packing',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          },
          {
            title: 'Pick List',
            icon: <PickList />,
            hard: true,
            to: '/orders?do=picklist',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          },
          {
            title: 'Upload Tracking',
            icon: <UploadTracking />,
            hard: true,
            to: '/#!/shipping/tracking',
            childTracker: { eventName: 'Orders: visit', data: { action: 'User clicks a link inside orders at the sidebar' } }
          }
        ]}
        scopes={['organization:orders:ui:read']}
      />
      {/*
      <SidebarSection
        title='Storefront'
        to='/#!/storefront'
        onClick={onClick}
        icon={<Storefront />}
        navLinks={[
          { title: 'Categories', hard: true, to: '/#!/categories' },
          { title: 'Pages', hard: true, to: '/#!/pages' },
          { title: 'Go To Storefront', hard: true, to: 'https://www.suredone.com' }
        ]}
      />
    */}
      <SidebarSection
        title='Bulk'
        to='/#!/bulk/'
        onClick={onClick}
        icon={<Bulk />}
        navLinks={[
          {
            title: 'Upload',
            icon: <BulkUploads />,
            hard: true,
            to: '/#!/bulk/',
            childTracker: { eventName: 'Bulk: visit', data: { action: 'User clicks on upload, results or export at the sidebar on the dashboard' } }
          },
          {
            title: 'Results',
            icon: <BulkResults />,
            hard: true,
            to: '/#!/bulk/results',
            childTracker: { eventName: 'Bulk: visit', data: { action: 'User clicks on upload, results or export at the sidebar on the dashboard' } }
          },
          {
            title: 'Export',
            icon: <Export />,
            hard: true,
            to: '/#!/bulk/exports',
            childTracker: { eventName: 'Bulk: visit', data: { action: 'User clicks on upload, results or export at the sidebar on the dashboard' } }
          }
        ]}
      />
      <ChannelSidebar onClick={onClick} />
      {storefrontEnabled &&
        <SidebarSection
          title='Storefront'
          to='/storefront'
          icon={<Storefront />}
          onClick={onClick}
          navLinks={[
            {
              title: 'Pages',
              hard: true,
              to: '/#!/pages',
              icon: <Pages />,
              childTracker: { eventName: 'Storefront: visit', data: { action: 'User clicks Pages inside Storefront at the sidebar' } }
            },
            {
              title: 'Categories',
              hard: true,
              to: '/#!/categories',
              icon: <Categories />,
              childTracker: { eventName: 'Storefront: visit', data: { action: 'User clicks Categories inside Storefront at the sidebar' } }
            }
          ]}
        />}
      <AutomationSidebar onClick={onClick} />
      {canViewReports && (
        <SidebarSection
          title='Reports'
          to='/reports'
          onClick={onClick}
          icon={<Reports />}
          parentTracker={{ eventName: 'Reports pages: visit', data: { action: 'User clicks Reports at the sidebar' } }}
        />
      )}
      {firstAuthorizedChannel && (
        <SidebarSection
          onClick={onClick}
          icon={<ErrorsIcon />}
          title={<>Errors <BetaBadge /></>}
          to={`/channels/errors/${formatSlug(channel?.slug)}/${instance}`}
          parentTracker={{ eventName: 'Channels Errors: visit', data: { action: 'User clicks Channels Errors at the sidebar' } }}
        />
      )}
      <SidebarSection
        title='Logs'
        to={`/logs/${organizationId}?${defaultLogsSearch}`}
        onClick={onClick}
        icon={<Logs />}
        parentTracker={{ eventName: 'Logs: visit', data: { action: 'User clicks Logs at the sidebar' } }}
      />
      {canViewFitment && <FitmentSidebar onClick={onClick} />}
      <SidebarSection
        to='/repricer'
        title={<>Repricer <BetaBadge /></>}
        onClick={onClick}
        icon={<Repricer />}
        parentTracker={{ eventName: 'Repricer: visit', data: { action: 'User clicks Repricer at the sidebar' } }}
      />
      <hr />
      <SidebarSection
        title='Settings'
        to='/settings'
        icon={<Settings />}
        onClick={onClick}
        navLinks={[
          {
            title: 'User',
            to: '/settings/user/profile',
            icon: <User />,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks User inside Settings at the sidebar' } }
          }, {
            title: 'Organization',
            // Links to ../members if user can view members but can't view organization settings, else, links to ../profile
            to: `${!canViewOrgSettings && canViewMembers ? '/settings/organization/members' : '/settings/organization/profile'}`,
            icon: <Organization />,
            // roles: ['Admin', 'PlatformSupport'],
            scopes: ['organization:settings:ui:read', 'organization:users:read'],
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Organization inside Settings at the sidebar' } }
          },
          {
            title: 'Billing',
            to: '/settings/billing/plan',
            icon: <Billing />,
            scopes: ['organization:settings:ui:read', 'organization:settings:ui:write'],
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Billing inside Settings at the sidebar' } }
          }, {
            title: 'Custom Fields',
            icon: <CustomFields />,
            to: '/settings/customfields',
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Custom Fields inside Settings at the sidebar' } }
          }, {
            title: 'Orders',
            to: '/settings/orders/general',
            icon: <List />,
            scopes: ['organization:settings:ui:read'],
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Orders inside Settings at the sidebar' } }
          }, {
            title: 'Shipping',
            to: '/settings/shipping/general',
            icon: <Shipping />,
            scopes: ['organization:settings:ui:read'],
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Shipping inside Settings at the sidebar' } }
          }, {
            title: 'Products & Inventory',
            to: '/settings/products-inventory',
            icon: <Products />,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Products & Inventory inside Settings at the sidebar' } }
          }, {
            title: 'Storefront',
            icon: <Storefront />,
            to: storefrontEnabled && '/settings/storefront',
            hideLink: !storefrontEnabled,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Storefront inside Settings at the sidebar' } }
          }, {
            title: 'Templates',
            to: '/settings/templates',
            icon: <Templates />,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Templates inside Settings at the sidebar' } }
          }, {
            title: 'Media Assets',
            to: '/settings/assets',
            icon: <Media />,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Media Assets inside Settings at the sidebar' } }
          }, {
          //   title: 'Notifications', – Uncomment when needed
          //   to: '/settings/notifications',
          //   icon: <Bell />,
          //   childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks Notifications inside Settings at the sidebar' } }
          // }, {
            title: 'All settings',
            to: '/settings/all',
            icon: <Settings />,
            childTracker: { eventName: 'Settings: visit', data: { action: 'User clicks All settings inside Settings at the sidebar' } }
          }
        ]}
      />
      <SidebarSection
        onClick={onClick}
        title='Admin'
        to='/admin'
        icon={<Admin />}
        scopes={['platform:allUsers:list',
          'platform:allOrganizations:list',
          'platform:graphqlPlayground:use']}
        navLinks={[
          {
            title: 'Users',
            to: '/admin/users',
            icon: <ManageUsers />,
            scopes: ['platform:allUsers:list']
          },
          {
            title: 'Deleted users',
            to: '/admin/users/deleted',
            icon: <DeletedUsers />,
            scopes: ['platform:allUsers:list']
          },
          {
            title: 'Organizations',
            to: '/admin/organizations',
            icon: <ManageOrganizations />,
            scopes: ['platform:allOrganizations:list']
          },
          {
            title: 'Create checkout',
            to: '/admin/create-checkout',
            icon: <Billing />,
            scopes: ['platform:allOrganizations:list']
          },
          {
            title: 'Tax Report',
            to: '/admin/tax-report',
            icon: <TaxReportIcon />,
            scopes: ['platform:allOrganizations:list']
          },
          {
            title: 'Internal Tools',
            to: '/admin/internal-tools',
            icon: <FaTools />,
            scopes: ['platform:allOrganizations:list']
          }
        ]}
      />
    </div>
  )
}

// Only on mobile
const SidebarHeader = ({ setSidebarOpen }) => {
  return (
    <header className='aside-header d-block d-md-none'>
      <UserDropdownMenuMobile setSidebarOpen={setSidebarOpen} />
    </header>
  )
}

export function Sidebar ({ sidebarOpen, setSidebarOpen }) {
  const { stores: { auth: { user } } } = useStores()

  const { data: storefrontData } = useQuery(GET_STOREFRONT)
  const { StorefrontStatus } = storefrontData || {}
  const { site } = StorefrontStatus || {}
  const storefrontEnabled = site === 'on'

  const { data: availableChannels } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances } = availableChannels || { ChannelInstances: [] }
  const authorizedChannels = ChannelInstances && getAuthorizedChannels(ChannelInstances)

  return (
    <aside key='app-aside' className={`app-aside app-aside-expand-md app-aside-light ${sidebarOpen ? 'show' : ''}`}>
      <div className='aside-content'>
        <SidebarHeader setSidebarOpen={setSidebarOpen} />
        <div className='aside-menu'>
          <div className='stacked-menu stacked-menu-has-collapsible'>
            <SidebarNav onClick={() => setSidebarOpen(false)} storefrontEnabled={storefrontEnabled} authorizedChannels={authorizedChannels} />
          </div>
        </div>
        <footer className='aside-footer border-top p-2 text-center'>
          <small className='text-muted'>
            {window.serverConfig.release}<br />
            {user?.sudoer?.email}
          </small>
        </footer>
      </div>
    </aside>
  )
}

debug('loaded')
