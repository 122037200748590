import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useDebounce } from '../../../hooks'
import { useQuery } from '@apollo/react-hooks'
import { AsyncSelect } from '../../../components'

export const GET_WALMART_CATEGORIES = gql`
query WalmartCategories ($search: String!) {
  WalmartCategories (search: $search) {
    name
    hierarchy
  }
}
`

export const WalmartCategoryFilter = ({ handleSelectCategory }) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounce(search, 300)

  const { loading, data } = useQuery(GET_WALMART_CATEGORIES, {
    variables: { search: debouncedSearch },
    skip: !debouncedSearch
  })
  const { WalmartCategories: matchedCategories } = data || {}
  const formattedOptions = matchedCategories?.map(({ name, hierarchy }) => ({
    value: name,
    label: name,
    helpText: hierarchy?.replaceAll('/', ' • ')
  }))

  const handleInputChange = (e) => {
    setSearch(e)
    if (!e) handleSelectCategory({ value: 'all' })
  }

  return (
    <div className='w-50'>
      <AsyncSelect
        value={search}
        loading={loading}
        setValue={setSearch}
        placeholder='Category'
        options={formattedOptions}
        onClickOption={handleSelectCategory}
        onInputChange={e => handleInputChange(e)}
      />
    </div>
  )
}
