import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { SidebarSection } from '../../components'
import { icons } from '../../scenes/Channel/ChannelsLogos'
import { AVAILABLE_CHANNELS } from '../Channel'
import { FaWrench as Fitment, FaFileContract as Report } from 'react-icons/fa'
const channelsToInclude = ['shopify', 'bigcommerce']

const generateLinks = instances => {
  const onlyAuthorized = instances?.filter(({ authStatus }) => authStatus === 'complete')
  const onlyShopifyAndBigcommerce = onlyAuthorized?.filter(({ channel }) => channelsToInclude.includes(channel?.slug))
  const formattedForSidebar = onlyShopifyAndBigcommerce?.map(({ channel: c, instance, customLabel }) => ({
    title: customLabel || `${c?.name} ${instance || ''}`,
    to: `/fitment/settings/${c.slug}/${instance}`,
    icon: icons[c.slug],
    childTracker: { eventName: 'Fitment Settings: visit', data: { action: `User clicks ${c.name} Settings inside Fitment at the sidebar` } }
  }))
  return formattedForSidebar
}

export const FitmentSidebar = ({ onClick }) => {
  const { data } = useQuery(AVAILABLE_CHANNELS)
  const { ChannelInstances } = data || { ChannelInstances: [] }
  const externalChannels = generateLinks(ChannelInstances)

  const channelsLinks = [
    ...externalChannels,
    {
      title: 'SureDone',
      to: '/fitment/settings/suredone',
      icon: icons.storefront,
      childTracker: { eventName: 'Fitment Settings: visit', data: { action: 'User clicks SureDone Settings inside Fitment at the sidebar' } }
    }
  ]

  return (
    <SidebarSection
      title='Fitment'
      onClick={onClick}
      icon={<Fitment />}
      to='/fitment/settings'
      parentTracker={{ eventName: 'Fitment: visit', data: { action: 'User clicks Fitment at the sidebar' } }}
      navLinks={[
        ...channelsLinks,
        {
          title: 'Report',
          to: '/fitment/report',
          icon: <Report />,
          childTracker: { eventName: 'Fitment Rerport: visit', data: { action: 'User clicks Report inside Fitment at the sidebar' } }
        }
      ]}
    />
  )
}
