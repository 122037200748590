import React from 'react'
import Debug from 'debug'
import { logos } from './ChannelsLogos'
import { checkVisibility } from './utils'
import { isAmazon } from 'suredone-common'
import { useQuery } from '@apollo/react-hooks'
import { Loadable, Page } from '../../components'
import { WalmartSpecificFields } from './Walmart'
import ChannelToggleEnabled from './ChannelToggleEnabled'
import { isChannelAuthorized } from '../../components/utils'
import { ChannelReportsContainer } from './ChannelReportsContainer'
import { GET_CHANNEL_INSTANCE, AVAILABLE_CHANNELS } from './commonQueries'
import { Badge, Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap'
import { NavLink as RNavLink, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { ChannelInstructions, ChannelManagement, ChannelSettings, ChannelImport, ChannelStockFulfillment, ChannelWizard } from '.'
import './ChannelDetail.scss'

const debug = Debug('sd:ChannelDetails')

export const channelsToShowLoadingText = ['shopify', 'bigcommerce']

export default withRouter(function ChannelDetail ({ match }) {
  const { slug, instanceNumber } = match.params
  const { error, data, loading } = useQuery(GET_CHANNEL_INSTANCE, {
    variables: { slug, instanceNumber: parseInt(instanceNumber) },
    onError: (error) => {
      debug({ error })
    }
  })
  const { Channel, ChannelInstance } = data || { Channel: {}, ChannelInstance: {} }
  debug({ Channel, slug, ChannelInstance })
  const { authSteps, name: channelName } = Channel || {}
  const { enabled, authStatus } = ChannelInstance || {}

  // AvailableChannels has information about token expiration
  const { data: availableChannels } = useQuery(AVAILABLE_CHANNELS)

  const routes = [
    {
      exact: true,
      name: 'Instructions',
      path: `${match.path}/instructions`,
      render: props => <ChannelInstructions slug={slug} instanceNumber={parseInt(instanceNumber)} />
    },
    {
      name: 'Authorization',
      path: `${match.path}/authorization`,
      render: () => {
        return <ChannelWizard slug={slug} channelName={channelName} instanceNumber={parseInt(instanceNumber)} authSteps={authSteps} availableChannels={availableChannels} />
      }
    },
    {
      exact: true,
      name: 'Settings',
      path: `${match.path}/settings`,
      render: props => <ChannelSettings slug={slug} instanceNumber={parseInt(instanceNumber)} isEnabled={enabled} authStatus={authStatus} />
    }, {
      exact: false,
      name: 'Import',
      path: `${match.path}/import`,
      render: props => <ChannelImport slug={slug} instanceNumber={parseInt(instanceNumber)} />
    }, {
      exact: true,
      name: 'Manage',
      path: `${match.path}/manage`,
      render: props => <ChannelManagement slug={slug} instanceNumber={parseInt(instanceNumber)} />
    }, {
      exact: true,
      name: 'Category Fields',
      path: `${match.path}/category-fields`,
      render: props => <WalmartSpecificFields slug={slug} instanceNumber={parseInt(instanceNumber)} />
    }, {
      exact: true,
      name: 'Stock Fulfillment',
      path: `${match.path}/stock-fulfillment`,
      render: props => <ChannelStockFulfillment slug={slug} instanceNumber={parseInt(instanceNumber)} />
    }, {
      exact: true,
      name: 'Reports',
      path: `${match.path}/reports`,
      render: props => <ChannelReportsContainer slug={slug} instanceNumber={parseInt(instanceNumber)} />
    }
  ]

  const channelsToShowStockFulfillment = ['shopify']
  const channelsToShowSpecificFields = ['walmart']

  const availableTabs = [
    { slug: 'instructions', label: 'Instructions', show: true },
    { slug: 'authorization', label: 'Authorization', show: true },
    { slug: 'settings', label: 'Settings', show: true },
    { slug: 'import', label: 'Import', show: true },
    { slug: 'manage', label: 'Manage Fields', show: true },
    { slug: 'category-fields', label: 'Category Fields', show: checkVisibility(slug, channelsToShowSpecificFields) },
    { slug: 'stock-fulfillment', label: 'Stock Fulfillment', show: checkVisibility(slug, channelsToShowStockFulfillment) },
    { slug: 'reports', label: 'Reports', show: isAmazon(slug) }
  ]

  return (
    <Page
      className='channel-detail'
      back={{ to: '/dashboard', name: 'Dashboard' }}
      title={
        <span className='custom-title'>
          {logos[slug]}
          {instanceNumber > 0 &&
            <Badge color='info'>Instance {instanceNumber}</Badge>}
        </span>
      }
      description={authStatus && !isChannelAuthorized({ authStatus, availableChannels, slug, instanceNumber }) &&
        <Badge color='danger'>Channel unauthorized</Badge>}
      actions={enabled !== undefined &&
        <div className='d-flex flex-column justify-content-center h-100 mt-3 mt-sm-n2'>
          <ChannelToggleEnabled id={slug + instanceNumber} active={enabled} slug={slug} instanceNumber={parseInt(instanceNumber)} />
          <span className='w-100 pt-2 align-self-center text-muted'> Channel {enabled ? 'enabled' : 'disabled'}</span>
        </div>}
    >
      <Card className='mt-2'>
        <CardHeader>
          <Nav tabs className='card-header-tabs'>
            {availableTabs.map(({ slug, label, show }) => show && (
              <NavItem key={slug}>
                <NavLink tag={RNavLink} to={`${match.url}/${slug}`}>
                  {label}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <Loadable
          inline
          error={error}
          loading={loading}
          style={{ padding: '1rem' }}
          loadingText={checkVisibility(slug, channelsToShowLoadingText) ? 'Please wait, we are importing your settings and refreshing fields' : null}
          content={
            <CardBody>
              <Switch>
                {routes.map((routeProps, index) => (<Route key={index} {...routeProps} />))}
                <Redirect to={`${match.url}/instructions`} />
              </Switch>
            </CardBody>
          }
        />
      </Card>
    </Page>
  )
})
