import { useHistory } from 'react-router'
import { useQueryParams } from '.'
import { useStores } from '../stores/RootStore'
import { addOrReplaceFilter } from '../scenes/Logs'
import { GET_LOGS } from '../scenes/Logs/commonQueries'
import { useQuery, useLazyQuery } from '@apollo/react-hooks'

/**
 * Receives filters.filters to prepare the query.
 * Since the query variable 'filter' needs to know the filterType, "filterType":"AND" is returned too.
 * page and size for the query variables 'limit' and 'offset' are not handlered here.
 * @param {[{key: string, opr: string, val: string}]} filters
 * @returns {{type: string, filters: [{key: string, opr: string, val: string}]}}
 */
const prepareFilterVariable = filters => {
  const addPlus = (val) => val?.split(' ').join('+').replace(',', '+')
  const formatted = filters?.map(({ key, opr, val }) => ({
    key: `${key}`,
    opr: `${opr}`,
    val: `${key === 'createdAt' ? addPlus(val) : val}` // Adds a '+' to createdAt date
  }))
  return { filterType: 'AND', filters: formatted }
}

const calcOffset = (pageSize, currentPage) => pageSize * currentPage
const calcLimit = (pageSize, currentPage) => pageSize

export const useLogs = ({ targetOrgData, defaultFilter }) => {
  const history = useHistory()
  const paramsObject = useQueryParams()
  const activeColumns = paramsObject?.columns
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { legacyAccountId } = user || {}
  const targetOrganizationId = targetOrgData?.id

  let urlFiltersToJSON
  try {
    // Let's parse filters from URL, so urlFiltersToJSON will have {type, filters, page, size}
    urlFiltersToJSON = JSON.parse(
      paramsObject?.filters || '{}',
      (k, v) => { // JSON.parse converts string spaces into ',' so here we force the spaces instead of ','
        if (k === 'val') {
          if (typeof v === 'string' || v instanceof String) {
            return v?.replaceAll(',', ' ')
          }
        }
        return v
      })
  } catch (e) {
    // Something went wrong parsing the URL. Let's check urlFiltersToJSON variable to show a message.
  }
  const activeFilters = urlFiltersToJSON?.filters
  const defaultOrActiveFilter = activeFilters?.length > 0 ? activeFilters : defaultFilter
  const currentPage = urlFiltersToJSON?.page || 0
  const pageSize = urlFiltersToJSON?.size || 10

  // This line is used to determinate the filter, limit and offset query's variables
  const filterToQuery = prepareFilterVariable(defaultOrActiveFilter)

  // Variables to get logs
  const userId = Number(legacyAccountId) // legacyAccountId to query logs
  const filter = filterToQuery // filters
  const limit = calcLimit(pageSize, currentPage)
  const offset = calcOffset(pageSize, currentPage)
  const orderBy = { createdAt: 'DESC' }
  const variables = { userId, targetOrganizationId, filter, limit, offset, orderBy }

  // Get logs when page loads, the response is cached by Apollo.
  const { loading, error, data, called } = useQuery(GET_LOGS, {
    variables,
    skip: filterToQuery?.filters?.length < 1 || !filterToQuery?.filters
  })

  const [refreshLogs, { loading: refreshing }] = useLazyQuery(GET_LOGS, {
    variables,
    fetchPolicy: 'network-only'
  })

  // Adds filters to the URL to query logs
  const handleAddFilter = event => {
    const { key, val, opr } = event || {}
    let newSearch
    const actualSearch = history.location.search
    const actualColumns = actualSearch?.split('&columns=')[1]
    const splittedByColumns = actualSearch?.split('&columns')[0]
    const splitted = splittedByColumns?.split('?filters=')[1]
    const decoded = splitted ? decodeURIComponent(splitted) : null
    const parsed = JSON.parse(decoded || '{}')
    const currentFilters = parsed?.filters
    const mergedFilters = addOrReplaceFilter(currentFilters || [], { key: `${key}`, opr: `${opr}`, val: `${val}` })
    const newFiltersAsString = encodeURIComponent(JSON.stringify(mergedFilters))
    if (actualColumns) {
      newSearch = `filters={"filterType":"AND","filters":${newFiltersAsString},"page":0,"size":${pageSize}}&columns=${actualColumns}`
    } else {
      newSearch = `filters={"filterType":"AND","filters":${newFiltersAsString},"page":0,"size":${pageSize}}`
    }
    history.push({ search: newSearch })
  }

  const handleRemoveFilter = event => {
    const updatedFilters = activeFilters?.filter(i => i.key !== event)
    let newSearch
    const newFilters = updatedFilters?.map(({ key, opr, val }) => `{"key":"${key}","opr":"${opr}","val":"${val}"}`).join(',')
    const actualSearch = history.location.search
    const actualColumns = actualSearch?.split('columns=')[1]
    const encodedNewFilters = encodeURIComponent(newFilters)
    if (actualColumns) {
      newSearch = `filters={"filterType":"AND","filters":[${encodedNewFilters || ''}],"page":0,"size":${pageSize}}&columns=${actualColumns}`
    } else {
      newSearch = `filters={"filterType":"AND","filters":[${encodedNewFilters || ''}],"page":0,"size":${pageSize}}`
    }
    history.push({ search: newSearch })
  }

  return { data, error, loading, called, currentPage, pageSize, activeFilters, activeColumns, urlFiltersToJSON, refreshing, handleAddFilter, handleRemoveFilter, refreshLogs }
}
