import React, { useState, useEffect } from 'react'
import gql from 'graphql-tag'
import FieldSummary from './../FieldSummary'
import { cleanPayload } from '../utils'
import { useStores } from '../../../stores/RootStore'
import { Loadable, ErrorAlert } from '../../../components'
import { SaveButton } from '../../../components/SaveButton'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { WalmartCategoryFilter, WalmartFieldSummary } from './'
import { ResourceList } from '../../../components/SearchableLists'
import { FormGroup, Label, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { GET_CHANNEL_FIELDS, UPDATE_CHANNEL_FIELDS } from '../commonQueries'
import WalmartCategoriesDownloader from './WalmartCategoriesDownloader'

const availableTabs = [
  { label: 'Common fields' },
  { label: 'Unit of measure fields' }
]

const GET_WALMART_FIELDS = gql`
query WalmartFields {
  WalmartFields {
    name
    label
    categories
    values
  }
}
`

export const WalmartSpecificFields = ({ slug, instanceNumber }) => {
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { organizationId } = user || {}

  const [activeTab, setActiveTab] = useState(0)
  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  // Options to select and fields saved information
  const { data: fieldsData, loading: fieldsLoading, error: fieldsError } = useQuery(GET_CHANNEL_FIELDS, {
    variables: {
      slug,
      instanceNumber,
      organizationId
    }
  })
  // orgMappableFields -> options to select
  const { OrganizationDetails } = fieldsData || { OrganizationDetails: {} }
  const { mappableProductFields: orgMappableFields } = OrganizationDetails || []
  // channelInstanceFieldMappings -> info about selected option per row
  const { ChannelInstance } = fieldsData || { ChannelInstance: {} }
  const { fieldMappings: channelInstanceFieldMappings } = ChannelInstance || []
  // Walmart attributes to configure (rows)
  const { data: walmartData, loading: walmartLoading, error: walmartError } = useQuery(GET_WALMART_FIELDS)
  const { WalmartFields: attributes } = walmartData || {}

  // Mutation stuff
  const [mutationError, setMutationError] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [updateFields, { loading: mLoading, data: mData }] = useMutation(
    UPDATE_CHANNEL_FIELDS,
    {
      onCompleted: () => {
        setIsDirty(false)
      },
      onError: (err) => {
        // debug('onError, e:', err)
        setMutationError(err || 'There was an error updating field mappings. Try again please.')
      }
    }
  )

  const [attributesRows, setAttributesRows] = useState([])
  const [newChannelInstance, setNewChannelInstance] = useState()
  const [fieldsToRemoveValue, setFieldsToRemoveValue] = useState([])
  const [filteredAttributes, setFilteredAttributes] = useState()
  const [selectedCategory, setSelectedCategory] = useState()

  const onlyCommonFields = (filteredAttributes || attributesRows)?.filter(i => !i.label?.includes('Unit of Measure'))
  const onlyUnitOfMeasure = (filteredAttributes || attributesRows)?.filter(i => i.label?.includes('Unit of Measure'))

  const handleSelectCategory = (event) => setSelectedCategory(event?.value)

  useEffect(() => {
    if (!selectedCategory || selectedCategory === 'all') {
      return setFilteredAttributes(attributesRows)
    }

    const attributesForThatCategory = attributesRows?.filter(i => i.categories?.includes(selectedCategory))
    setFilteredAttributes(attributesForThatCategory)
  }, [attributesRows, selectedCategory, newChannelInstance])

  useEffect(() => {
    const attrRows = attributes
    const config = channelInstanceFieldMappings
    const fullAttributesRows = (attrRows || []).map((i, idx) => ({
      ...i,
      value: config?.find(c => c.organizationFieldName === i.name),
      key: (i.channelFieldName || i.name) + idx,
      generalIndex: idx
    }))
    setAttributesRows(fullAttributesRows)
  }, [attributes, channelInstanceFieldMappings])

  const handleChangedValue = (event, field) => {
    setIsDirty(true)
    setMutationError(false)
    const copyOfAttributeRows = attributesRows

    if (!event?.value) {
      setFieldsToRemoveValue(prev => [...prev, field.name])
    } else {
      setFieldsToRemoveValue(prev => prev?.filter(i => i !== field.name))
    }

    const newConfig = {
      ...field,
      value: {
        channelFieldName: (event && event.value) || null,
        organizationFieldName: field.name
      }
    }

    copyOfAttributeRows[field.generalIndex] = newConfig
    setAttributesRows(copyOfAttributeRows)

    const valuesToSaveOnly = copyOfAttributeRows
      ?.filter(i => (i.value && i.value.channelFieldName) ? i : null)
      ?.map(i => i.value)

    setNewChannelInstance(valuesToSaveOnly)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let mergedOldAndNewConfigs
    if (newChannelInstance) {
      mergedOldAndNewConfigs = [...channelInstanceFieldMappings, ...newChannelInstance]
    }

    // Removes duplicated keys and keep the latest updated
    const filtered = cleanPayload(mergedOldAndNewConfigs, fieldsToRemoveValue)

    updateFields({
      variables: {
        slug,
        instanceNumber,
        fieldMappings: filtered
      }
    })
  }

  return (
    <Loadable
      inline
      loading={fieldsLoading || walmartLoading}
      error={fieldsError || walmartError}
      style={{ textAlign: 'center' }}
      content={
        <div className='tabbed-field-mappings'>
          <FormGroup tag='fieldset' className='m-0 p-3 py-4 additional-filter-wrapper'>
            <Label className='mr-3 my-0'>Category</Label>
            <WalmartCategoryFilter handleSelectCategory={handleSelectCategory} />
          </FormGroup>
          <WalmartCategoriesDownloader />
          <form className='form p-0' onSubmit={handleSubmit}>
            <Nav tabs>
              {availableTabs.map((i, idx) =>
                <NavItem key={idx}>
                  <NavLink
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleTab(idx)}
                    className={activeTab === idx ? 'active' : ''}
                  >
                    {i.label}
                  </NavLink>
                </NavItem>
              )}
            </Nav>

            {/* Common fields */}
            <TabContent activeTab={activeTab}>
              <TabPane tabId={0}>
                <ResourceList
                  className='channel-management'
                  items={onlyCommonFields}
                  searchableStrings={a => [a.organizationFieldName, a.label]}
                  itemComponent={
                    ({ item: field, searchTerms }) =>
                      <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item'>
                        <FieldSummary
                          isListItem
                          field={field}
                          searchTerms={searchTerms}
                          parentCallback={handleChangedValue}
                          orgMappableFields={orgMappableFields}
                        />
                      </li>
                  }
                />
              </TabPane>

              {/* Unit of measure fields */}
              <TabPane tabId={1}>
                <ResourceList
                  className='channel-management'
                  items={onlyUnitOfMeasure}
                  searchableStrings={a => [a.organizationFieldName, a.label]}
                  itemComponent={
                    ({ item: field, searchTerms }) =>
                      <li className='list-group-item-flush d-flex flex-sm-row flex-column list-group-item-action list-group-item'>
                        <WalmartFieldSummary
                          isListItem
                          field={field}
                          searchTerms={searchTerms}
                          handleChangedValue={handleChangedValue}
                        />
                      </li>
                  }
                />
              </TabPane>
            </TabContent>

            {mutationError && <ErrorAlert error={mutationError} />}

            <div className='d-flex justify-content-end'>
              <SaveButton
                type='submit'
                color='primary'
                loading={mLoading}
                disabled={!isDirty}
                success={mData ? 'true' : null}
                dirtysincelastsubmit={isDirty ? 'true' : null}
              />
            </div>
          </form>
        </div>
      }
    />
  )
}
