import React from 'react'
import { CSVLink } from 'react-csv'
import { ActionButton } from '../components'
import { FaDownload as Download } from 'react-icons/fa'

/**
  Data should be like the following
  data = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
  ]
*/
export const DownloaderCSV = ({ data, text, icon, color = 'primary', filePrefix = 'logs-', ...rest }) => {
  return (
    <CSVLink data={data} filename={`${filePrefix}${Date.now()}.csv`}>
      <ActionButton
        outline
        {...rest}
        color={color}
        icon={icon || <Download size={14} style={{ margin: '-4px 8px 0 0' }} />}
      >
        {text || 'Download'}
      </ActionButton>
    </CSVLink>
  )
}
