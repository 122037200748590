/**
 * Main component controlling what a user sees once they are logged in
 */
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from './stores/RootStore'
import { Route, Redirect, Switch } from 'react-router-dom'
import { Container, Alert } from 'reactstrap'
import asyncComponent from './components/AsyncComponent'
import { Dashboard } from './scenes/Dashboard/Dashboard'
import { RegistrationWizard } from './scenes/WizardRegister'
import { OnDemo } from './scenes/OnDemo/OnDemo'
import { MFARequiredWall } from './components/MFA/MFARequiredWall'
import { StatusesFrame } from './scenes/Statuses'
import { useQuery } from '@apollo/react-hooks'
import { GET_ORGANIZATION_DETAILS } from './scenes/OrganizationManager/commonQueries'
import { DeactivatedWall } from './scenes/DeactivatedWall'
import { Loading, MainNavbar, Sidebar, Notifications, HotjarUpdates, IntercomUpdates, KeyboardLookup } from './components'
import { FitmentResults } from './scenes/Fitment'
import { RedirectToCheckout } from './components/RedirectToCheckout/RedirectToCheckout'
import { SHOW_TOS_REQUIRED_WALL, TOSRequiredWall } from './components/TermsOfService'
// import { NotificationsDrawer } from './components/NotificationsDrawer/NotificationsDrawer' – Uncomment when needed

const debug = require('debug')('sd:LoggedIn')

const pathsToAvoidDashboardRedirect = [
  '/',
  '/login',
  '/logout',
  '/confirm',
  '/register',
  '/registration',
  '/reset-password',
  '/load/bigcommerce'
]

// Is a copy of /nginx.conf
// If the app cannot root the current path and it's not in this list, the user will be redirected to the dashboard.
const validPaths = ['/product-updates', '/fitment-advance-instructions', '/search-instructions', '/dashboard', '/newui', '/v3', '/channels', '/wizard-registration', '/wizard-register', '/auth', '/automations', '/users', '/playground', '/auth-refresh', '/redirect', '/admin', '/settings/user', '/settings/organization', '/settings/all', '/settings/shipping', '/settings/products-inventory', '/settings/orders', '/settings/billing', '/settings/customfields', '/settings/assets', '/logs', '/statuses', '/statuses-frame', '/reports', '/search-builder', '/terms']

const getRedirectLink = (initialPathname, initialSearch) => {
  if (!pathsToAvoidDashboardRedirect.includes(initialPathname)) {
    const initialPathArray = initialPathname.split('/')
    const thirdPartyRedirect = initialPathArray.length > 0 && (initialPathArray[1] === 'auth')
    if (validPaths.includes(initialPathname) || thirdPartyRedirect) {
      return { pathname: initialPathname, search: initialSearch }
    }
  }
  return '/dashboard'
}

const AsyncAutomationContainer = asyncComponent(() => import('./scenes/Automation/AutomationContainer'))
const AsyncLogsContainer = asyncComponent(() => import('./scenes/Logs/LogsContainer'))
const AsyncFromLegacy = asyncComponent(() => import('./scenes/Logs/FromLegacy'))
const AsyncStatusesContainer = asyncComponent(() => import('./scenes/Statuses/StatusesContainer'))
const AsyncUserManagerContainer = asyncComponent(() => import('./scenes/UserManager/UserManagerContainer'))
const AsyncOrganizationManagerContainer = asyncComponent(() => import('./scenes/OrganizationManager/OrganizationManagerContainer'))
const AsyncCheckoutManagerContainer = asyncComponent(() => import('./scenes/CheckoutManager/CheckoutManagerContainer'))
const AsyncTaxReportContainer = asyncComponent(() => import('./scenes/TaxReport/TaxReportContainer'))
const AsyncInternalToolsContainer = asyncComponent(() => import('./scenes/InternalTools/InternalToolsContainer'))
const AsyncReportsContainer = asyncComponent(() => import('./scenes/Reports/ReportsContainer'))
const AsyncRepricerContainer = asyncComponent(() => import('./scenes/Repricer/RepricerContainer'))
const AsyncCustomFieldsContainer = asyncComponent(() => import('./scenes/CustomFields/CustomFieldsContainer'))
const AsyncUserSettingsContainer = asyncComponent(() => import('./scenes/UserSettings/UserSettingsContainer'))
const AsyncChannelContainer = asyncComponent(() => import('./scenes/Channel/ChannelContainer'))
const AsyncSearchBuilderContainer = asyncComponent(() => import('./scenes/Search/SearchBuilder/SarchBuilderContainer'))
const AsyncSiteSettingsContainer = asyncComponent(() => import('./scenes/SiteSettings/SiteSettingsContainer'))
const AsyncChannelAuthContainer = asyncComponent(() => import('./scenes/Channel/ChannelAuth'))
const AsyncOrdersContainer = asyncComponent(() => import('./scenes/Orders/OrdersContainer'))
const AsyncShippingContainer = asyncComponent(() => import('./scenes/Shipping/ShippingContainer'))
const AsyncProductsAndInventoryContainer = asyncComponent(() => import('./scenes/ProductsAndInventory/ProductsAndInventoryContainer'))
const AsyncBillingContainer = asyncComponent(() => import('./scenes/Billing/BillingContainer'))
const AsyncFitmentContainer = asyncComponent(() => import('./scenes/Fitment/FitmentContainer'))
const AsyncTemplatesContainer = asyncComponent(() => import('./scenes/Templates/TemplatesContainer'))
const AsyncAssetsContainer = asyncComponent(() => import('./scenes/Assets/AssetsContainer'))
const AsyncNotificationsContainer = asyncComponent(() => import('./scenes/Notifications/NotificationsContainer'))
const AsyncStorefrontContainer = asyncComponent(() => import('./scenes/Storefront/StorefrontContainer'))

function MainContainer ({ sidebarOpen, setSidebarOpen, drawerOpen, setDrawerOpen, children }) {
  return [
    <Sidebar key='sidebar' sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />,
    // <NotificationsDrawer key='drawer' drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />, – Uncomment when needed
    <main key='app-main' className='app-main'>
      <div className='wrapper'>
        {children}
      </div>
    </main>,
    <div key='aside-backdrop' onClick={() => setSidebarOpen(false)} className={`aside-backdrop ${sidebarOpen ? 'show' : ''}`} />,
    <div key='drawer-backdrop' onClick={() => setDrawerOpen(false)} className={`drawer aside-backdrop ${drawerOpen ? 'show' : ''}`} />
  ]
}

export function _LoggedIn () {
  const { stores } = useStores()
  const { auth } = stores
  const { initialPathname, initialSearch, user } = auth || {}
  const { organizationId, id: userId } = user || {}
  const { loading: orgLoading, data: orgData, error } = useQuery(GET_ORGANIZATION_DETAILS, {
    skip: !organizationId,
    variables: { id: organizationId }
  })
  const { OrganizationDetails } = orgData || {}
  const { demoWall, deactivatedWall, stripeId, isLegacyCustomer, legacyAccountId } = OrganizationDetails || {}
  const { data: TOSdata } = useQuery(SHOW_TOS_REQUIRED_WALL, { variables: { id: userId } })
  const { showTOSRequiredWall } = TOSdata || {}
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)

  // Prevents double scroll when notifications drawer is open
  useEffect(() => {
    if (drawerOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [drawerOpen])

  const canViewFitment = auth?.permissions?.hasScopeInOrganization('organization:fitment:read', organizationId)
  const useLegacyLogin = false // (window.serverConfig || {}).production === true
  // If user has no legacyAccountId means that is a new organization client (AuthStore.js setStoreState func)
  // also if has missing stripeId and is NOT a legacy user, redirect user to wizard registration
  if (auth.state === auth.states.wizardLoggedIn || (
    !error && !orgLoading && !stripeId && !isLegacyCustomer && legacyAccountId !== 1 && auth.permissions.hasRole('Owner')
  )) { // Avoid suredone org
    return (
      <div className='auth'>
        <Switch>
          <Route path='/wizard-register' name='Wizard instructions' component={RegistrationWizard} />
          <Redirect to='/wizard-register' />
        </Switch>
      </div>
    )
  }
  // Wall for the user who has scheduled a demo
  if (demoWall) {
    return (
      <Switch>
        <Route path='/demo' component={() => <OnDemo orgData={OrganizationDetails} organizationId={organizationId} />} />
        <Redirect to='/demo' />
      </Switch>
    )
  }
  if (showTOSRequiredWall) {
    return (
      <Switch>
        <Route path='/terms' component={() => <TOSRequiredWall />} />
        <Redirect to='/terms' />
      </Switch>
    )
  }
  // Wall for the user who has deactivated the account
  if (deactivatedWall) {
    return (
      <Switch>
        <Route path='/close-account' component={() => <DeactivatedWall />} />
        <Redirect to='/close-account' />
      </Switch>
    )
  }
  return (
    auth.booting || orgLoading
      ? <Loading />
      : (
        <Switch>
          <Route path='/statuses-frame/:identifier'>
            <StatusesFrame />
          </Route>

          <Route path='/fitment/result'>
            {!canViewFitment && <Redirect to='/dashboard' />}
            <FitmentResults />
          </Route>

          <Route path='/'>
            <MainNavbar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
            <MainContainer sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}>
              <Notifications />
              <HotjarUpdates />
              <IntercomUpdates />
              <MFARequiredWall />
              <KeyboardLookup />
              <RedirectToCheckout orgData={OrganizationDetails} />

              <Switch>
                <Route
                  path='/auth-refresh/:uri'
                  name='Auth Refresh'
                  render={({
                    match: { params, isExact, path, url },
                    location,
                    history
                  }) => {
                    // This route simply waits for the SPA level auth machinery to refresh any relevant sessions,
                    // then render the route, and once it has, forward the user to the given location
                    // This is useful for the legacy app when it has an expired token
                    const uri = decodeURIComponent(params.uri)
                    if (window.location.href !== uri) window.location = uri
                  }}
                />
                <Route path='/redirect'>
                  <Redirect to='/' />
                </Route>
                <Route path='/logout' render={() => auth.logOut()} />
                <Route
                  path='/automations'
                  name='Automation List'
                  component={AsyncAutomationContainer}
                />
                <Route
                  path='/search-builder'
                  name='Search Builder'
                  component={AsyncSearchBuilderContainer}
                />
                <Route
                  path='/channels'
                  name='Channels'
                  component={AsyncChannelContainer}
                />
                <Route
                  path='/logs/:organizationID'
                  name='Logs'
                  component={AsyncLogsContainer}
                />
                <Route
                  path='/logs-from-legacy/:legacyAccountId'
                  name='Logs from legacy'
                  component={AsyncFromLegacy}
                />
                <Route
                  path='/statuses'
                  name='Statuses'
                  component={AsyncStatusesContainer}
                />
                <Route
                  path='/auth'
                  name='Auth'
                  component={AsyncChannelAuthContainer}
                />
                <Route
                  path='/reports'
                  name='Reports'
                  component={AsyncReportsContainer}
                />
                <Route
                  path='/repricer'
                  name='Repricer'
                  component={AsyncRepricerContainer}
                />
                <Route
                  path='/fitment'
                  name='Fitment'
                  component={AsyncFitmentContainer}
                />
                <Route
                  path='/admin/users'
                  name='User Manager'
                  component={AsyncUserManagerContainer}
                />
                <Route
                  path='/admin/organizations'
                  name='Organization Manager'
                  component={AsyncOrganizationManagerContainer}
                />
                <Route
                  path='/admin/create-checkout'
                  name='Organization Manager'
                  component={AsyncCheckoutManagerContainer}
                />
                <Route
                  path='/admin/tax-report'
                  name='Tax Report'
                  component={AsyncTaxReportContainer}
                />
                <Route
                  path='/admin/internal-tools'
                  name='Internal Tools'
                  component={AsyncInternalToolsContainer}
                />
                <Route
                  path='/settings/orders'
                  name='Orders'
                  component={AsyncOrdersContainer}
                />
                <Route
                  path='/settings/shipping'
                  name='Shipping'
                  component={AsyncShippingContainer}
                />
                <Route
                  path='/settings/products-inventory'
                  name='Products and Inventory'
                  component={AsyncProductsAndInventoryContainer}
                />
                <Route
                  path='/settings/all'
                  name='All settings'
                  component={AsyncSiteSettingsContainer}
                />
                <Route
                  path='/settings/billing'
                  name='Billing page'
                  component={AsyncBillingContainer}
                />
                <Route
                  path='/settings/customfields'
                  name='Custom Fields'
                  component={AsyncCustomFieldsContainer}
                />
                <Route
                  path='/settings/templates'
                  name='Templates'
                  component={AsyncTemplatesContainer}
                />
                <Route
                  path='/settings/storefront'
                  name='Storefront'
                  component={AsyncStorefrontContainer}
                />
                <Route
                  path='/settings/assets'
                  name='Products and Inventory'
                  component={AsyncAssetsContainer}
                />
                <Route
                  path='/settings/notifications'
                  name='Notifications'
                  component={AsyncNotificationsContainer}
                />
                <Route
                  path='/settings'
                  name='Settings page'
                  component={AsyncUserSettingsContainer}
                  stores={stores}
                />
                <Route
                  path='/dashboard'
                  name='Dashboard'
                  component={Dashboard}
                  stores={stores}
                />
                {useLegacyLogin
                  ? (
                    <Route
                      path='/'
                      render={() => {
                        auth.redirectToLegacy()
                        return (
                          <Container fluid>
                            <Alert color='info m-4'>Redirecting to legacy app...</Alert>
                          </Container>
                        )
                      }}
                    />)
                  : <Redirect to={getRedirectLink(initialPathname, initialSearch)} />}
                {auth.redirectLoopDetected
                  ? (
                    <Route
                      path='/'
                      render={() => {
                        return (
                          <Container fluid>
                            <Alert color='info m-4'>
                              Legacy app not available. In production this would
                              redirect.
                            </Alert>
                          </Container>
                        )
                      }}
                    />)
                  : null}
                <Loading />
              </Switch>
            </MainContainer>
          </Route>
        </Switch>)
  )
}

const LoggedIn = observer(_LoggedIn)

export default LoggedIn

debug('loaded')
