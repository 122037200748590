import gql from 'graphql-tag'

export const GET_INITIAL_OPTIONS = gql`
  query getInitialOptions ($inStock: Boolean) {
    getInitialOptions (inStock: $inStock) {
      _id
      year
      make
      model
      }
    }
  `

export const GET_RESULTS = gql`
  query getResults ($userId: Int!, $domain: StrippedProtocolUrl!, $year: Int, $make: String, $model: String, $filter: JSONObject!, $targetOrganizationId: String, $offset: Int, $limit: Int, $storefront: FitmentChannels) {
    getResults (userId: $userId, domain: $domain, year: $year, make: $make, model: $model, filter: $filter, targetOrganizationId: $targetOrganizationId, offset: $offset, limit: $limit, storefront: $storefront)
  }
`

export const GET_FITMENT_STATUS = gql`
  query getFitmentStatus ($targetOrganizationId: String!) {
    getFitmentStatus (targetOrganizationId: $targetOrganizationId)
  }
`

export const ENABLE_FITMENT_STATUS = gql`
  mutation enableFitmentStatus ($targetOrganizationId: String!, $enable: Boolean, $ebayInstance: Int, $usingStorefront: Boolean) {
    enableFitmentStatus (targetOrganizationId: $targetOrganizationId, enable: $enable, ebayInstance: $ebayInstance, usingStorefront: $usingStorefront)
  }
`

export const GET_DEFAULT_FACETS = gql`
  query getDefaultFacetsObjects {
    getDefaultFacetsObjects
  }
`

export const GET_AVAILABLE_FACETS = gql`
  query getAvailableFacetsObjects {
    getAvailableFacetsObjects {
      name
      label
    }
  }
`

export const SET_FACETS = gql`
  mutation setFacets ($slug: String!, $instanceNumber: Int!, $newCustomFacetSet: [String]!, $multiFacetsSet: [String]) {
    setFacets (slug: $slug, instanceNumber: $instanceNumber, newCustomFacetSet: $newCustomFacetSet, multiFacetsSet: $multiFacetsSet)
  }
`

export const GET_ACTIVE_FACETS = gql`
  query getFacetsByChannelInstance ($slug: String!, $instanceNumber: Int!) {
    getFacetsByChannelInstance (slug: $slug, instanceNumber: $instanceNumber)
  }
`

export const GET_FITMENT_REPORT = gql`
  query getFitmentReport ($sku: String, $year: Int, $make: String, $model: String, $limit: Int, $offset: Int) {
    getFitmentReport(sku: $sku, year: $year, make: $make, model: $model, limit: $limit, offset: $offset)
  }
`
export const GET_FITMENT_REPORT_CSV_AND_UPLOAD = gql`
    query getFitmentReportCSVAndUpload ($sku: String, $year: Int, $make: String, $model: String) {
    getFitmentReportCSVAndUpload(sku: $sku, year: $year, make: $make, model: $model)
  } `

export const GET_YEARS_FOR_REPORT = gql`
   query getYearsForReport {
    getYearsForReport{
      _id
    }
  }
`
export const GET_MAKES_FOR_REPORT = gql`
  query getMakesForReport($year: Int) {
    getMakesForReport(year: $year){
      _id
    }
  }
`
export const GET_MODELS_FOR_REPORT = gql`
  query getModelsForReport($year: Int, $make: String) {
    getModelsForReport(year: $year, make: $make){   
      _id
    }
  }
`
