/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { Row, Collapse, Card, CardHeader, FormGroup, Label, FormFeedback, Input, FormText } from 'reactstrap'
import { AuthButton } from '../'
import { Form, Field } from 'react-final-form'
import { VerticalStep } from './VerticalStepper'
import { SelectOnBoard } from './SelectOnBoard'
import { FaAngleDown } from 'react-icons/fa'
import { people } from '../../../../img/illustrations'
import { fieldModifiers } from './modifiers'
import { useQuery, useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'

// When content involves images, we need to include them in our bundle, so we
// use this map to define step instructions.
import { stepInstructions as FacebookStepInstructions } from './Facebook/StepInstructions'
import { stepInstructions as GoogleStepInstructions } from './Google/StepInstructions'
import { stepInstructions as BigCommerceStepInstructions } from './BigCommerce/StepInstructions'
import { stepInstructions as ShopifyStepInstructions } from './Shopify/StepInstructions'
import { stepInstructions as EbayStepInstructions } from './Ebay/StepInstructions'
import { stepInstructions as AmazonStepInstructions } from './Amazon/StepInstructions'
import { stepInstructions as EtsyStepInstructions } from './Etsy/StepInstructions'
import { Link } from 'react-router-dom'
import { EmptyState, Loadable, ActionButton, ErrorAlert } from '../../../../components'
import { GET_ONBOARD_DATA, UPDATE_ONBOARD_DATA } from '../../../Dashboard/commonQueries'
import { useReward } from 'react-rewards'
import { EbayOrgLocation } from '../../Ebay/EbayOrgLocation'
import { useStores } from '../../../../stores/RootStore'
import { AuthorizedShopifyStores } from '../ChannelContent/Shopify/AuthorizedShopifyStores'
import { ShopifyCustomAuth } from './Shopify/ShopifyCustomAuth'
import { MivaCustomAuth } from './Miva/MivaCustomAuth'
import Select from 'react-select'
import { amazonSlugs, amazonLegacy, amazonNew, isAmazon, configData } from 'suredone-common'
import { confetti } from '../../../../components/utils'

const prettifySlug = slug => slug === 'amzn' ? 'Amazon' : slug

const getOptions = (slug, fieldToGetOptions) => {
  if (isAmazon(slug)) {
    const rawObject = configData.amazon?.default?._system?.register_settings?.creds[fieldToGetOptions]?.values
    return rawObject && Object.keys(rawObject)?.map(k => ({ value: rawObject[k], label: k }))
  }
  return null
}

const stepsInstructionsBySlug = {
  facebook: FacebookStepInstructions,
  google: GoogleStepInstructions,
  bigcommerce: BigCommerceStepInstructions,
  shopify: ShopifyStepInstructions,
  ebay: EbayStepInstructions,
  [amazonLegacy]: AmazonStepInstructions,
  [amazonNew]: AmazonStepInstructions,
  etsy: EtsyStepInstructions
}

const REAUTHORIZE_CHANNEL = gql`
  mutation reAuthorizeChannel($slug: String!, $instanceNumber: Int!) {
    reAuthorizeChannel(instanceNumber: $instanceNumber, slug: $slug) {
      url
    }
  }
`

const debug = require('debug')('sd:StepGenericContent')

export const StepInstructionsInfo = (props) => {
  const { stepInstructions } = props
  return (stepInstructions || []).map((step, idx) => {
    const { title, description, imgSrc, imgAlt } = step
    return (
      <div key={`${title}${idx}`} className='max-80'>
        <VerticalStep
          number={idx + 1}
          title={title}
          description={description}
          imgSrc={imgSrc}
          imgAlt={imgAlt}
        />
      </div>
    )
  }
  )
}

/**
 * Generic component that shows the Instructions of this step and a button that redirects to other platform for let the user authorize suredone
 * @param {{slug:String, instanceNumber: Int}} props
 */

const StepRedirectContent = (props) => {
  debug('PROPS', props)
  const { slug, instanceNumber, step, channelName, auth_data, auth_loading, auth_error } = props
  const { instructions } = (stepsInstructionsBySlug[slug] || {})[step.name] || {}
  debug({ instructions })
  const { title, stepInstructions } = instructions || {}
  debug({ title, stepInstructions })
  const { ChannelInstance } = auth_data || {}
  const { authUrl } = ChannelInstance || {}
  const { commerceManagers } = authUrl || {}
  const userHasCommerceManagers = (commerceManagers || []).length > 0
  const [typeOfOnBoard, setTypeOfOnBoard] = useState()

  const newOnBoard = (
    <>
      <StepInstructionsInfo stepInstructions={stepInstructions} />

      <div className='d-flex justify-content-end mt-4'>
        <AuthButton slug={slug} channelName={channelName} instanceNumber={instanceNumber} step={step} />
      </div>
    </>
  )

  return (
    <Loadable
      loading={auth_loading}
      inline
      error={auth_error}
      content={
        // userHasCommerceManagers ? Show created commerces and new onboard option : show the usual new onboard
        userHasCommerceManagers
          ? (
            <>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-bottom-0' style={{ cursor: 'pointer' }} onClick={() => setTypeOfOnBoard('old-commerce')}>
                  <h6 className='d-flex align-items-center mb-0'> Use a created Commerce Account </h6>
                  <FaAngleDown size={24} color='#bbb' className='mr-n1' />
                </CardHeader>
                <Collapse isOpen={typeOfOnBoard === 'old-commerce'} className='px-3 pb-3 pt-0'>
                  <SelectOnBoard
                    slug={slug}
                    instanceNumber={instanceNumber}
                    commerceManagers={commerceManagers}
                  />
                </Collapse>
              </Card>
              <Card>
                <CardHeader className='d-flex align-items-center justify-content-between border-bottom-0' style={{ cursor: 'pointer' }} onClick={() => setTypeOfOnBoard('new-commerce')}>
                  <h6 className='d-flex align-items-center mb-0'> Create a new Commerce Account </h6>
                  <FaAngleDown size={24} color='#bbb' className='mr-n1' />
                </CardHeader>
                <Collapse isOpen={typeOfOnBoard === 'new-commerce'} className='p-3'>
                  {newOnBoard}
                </Collapse>
              </Card>
            </>)
          : newOnBoard
      }
    />
  )
}

const StepOnlyInfoContent = (props) => {
  debug('PROPS', props)
  const { slug, step, auth_loading, auth_error } = props
  const { instructions } = (stepsInstructionsBySlug[slug] || {})[step.name] || {}
  debug({ instructions })
  const { title, stepInstructions } = instructions || {}
  debug({ title, stepInstructions })

  return (
    <Loadable
      loading={auth_loading}
      inline
      error={auth_error}
      content={<StepInstructionsInfo stepInstructions={stepInstructions} />}
    />
  )
}

// This step renders a form to obtain the necessary information from the user
// The needed fields can be configurated in auth_steps.userInputNeeded on, ie, shopify-config.json
const StepFormContent = (props) => {
  const { slug, instanceNumber, step, channelName, userInputNeeded, auth_loading, auth_error /* paramObject */ } = props
  const useCustomApp = true // todo:: restore to the folowing when resubmit the app: Object.keys(paramObject)?.map(i => i?.toLowerCase())?.includes('usecustomapp')
  const { name, linkInstructions } = step || {}
  const { instructions } = (stepsInstructionsBySlug[slug] || {})[name] || {}
  const { stepInstructions } = instructions || {}

  if (useCustomApp && slug === 'shopify') return <ShopifyCustomAuth props={props} />
  if (slug === 'miva') return <MivaCustomAuth props={props} />

  const renderFormContent = props => {
    const { handleSubmit, errors, values } = props
    // shouldBeEnabled will be true when errors object is empty
    const shouldBeEnabled = Object.keys(errors).length === 0 && errors.constructor === Object

    const handleModifiers = (e, modifiers) => {
      const value = e.target.value
      const modifiedValue = (modifiers || []).map(i => fieldModifiers[i](value))
      return (modifiedValue || [])[0]
    }

    return (
      <Row className='mt-4'>
        <form className='max-80 w-100' onSubmit={handleSubmit}>
          {linkInstructions?.url && (
            <a
              className='d-block mb-3'
              rel='noopener noreferrer'
              href={linkInstructions.url}
              style={{ fontSize: '1rem' }}
              target={linkInstructions.openInNewTab ? '_blank' : '_self'}
            >
              {linkInstructions?.label}
            </a>
          )}

          {/* Avoid showing this for Shopify because it's addressed in <ShopifyCustomAuth /> */}
          {slug !== 'shopify' && (userInputNeeded || []).map(item => {
            const { name, label, required, modifiers, hint, type, fieldToGetOptions } = item
            return (
              <FormGroup key={name}>
                <div className='form-label-group select-with-label'>
                  <Field
                    name={name}
                    validate={required ? e => e ? undefined : `${label} is required` : null}
                  >
                    {({ input, meta }) => (
                      <>
                        {type === 'select'
                          ? (
                            <>
                              <Select
                                id={name}
                                {...input}
                                placeholder=''
                                clearable={false}
                                invalid={meta.touched && meta.invalid}
                                options={getOptions(slug, fieldToGetOptions)}
                                style={{ borderColor: meta.touched && meta.invalid ? '#dc3545' : '#aaa', height: '47px' }}
                              />
                              <Label for={name}>{label}</Label>
                              {meta.error && meta.touched && <div class='text-danger'><small>{meta.error}</small></div>}
                            </>
                            )
                          : (
                            <>
                              <Input
                                id={name}
                                {...input}
                                type='text'
                                onChange={e => (modifiers || []).length > 0
                                  ? input.onChange(handleModifiers(e, modifiers))
                                  : input.onChange(e)}
                                placeholder={label}
                                invalid={meta.touched && meta.invalid}
                              />
                              <Label for={name}>{label}</Label>
                              {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                            </>
                            )}
                      </>
                    )}
                  </Field>
                  {hint && <FormText>{hint}</FormText>}
                </div>
              </FormGroup>
            )
          })}

          {slug === 'shopify' && <AuthorizedShopifyStores values={values} />}

          <div className='d-flex justify-content-end mt-4'>
            <AuthButton
              slug={slug}
              values={values}
              channelName={channelName}
              disabled={!shouldBeEnabled}
              instanceNumber={instanceNumber}
            />
          </div>
        </form>
      </Row>
    )
  }

  return (
    <Loadable
      inline
      error={auth_error}
      loading={auth_loading}
      content={
        <>
          <StepInstructionsInfo stepInstructions={stepInstructions} />
          <Form onSubmit={() => { }} render={renderFormContent} />
        </>
      }
    />
  )
}

const StepFormSubmitContent = (props) => {
  const { stores: { auth } } = useStores()
  const { user } = auth || {}
  const { organizationId } = user || {}
  return (
    <EbayOrgLocation organizationId={organizationId} {...props} />
  )
}

const StepCompleteContent = (props) => {
  const { slug, instanceNumber, availableChannels } = props
  const { readyToGo, authentication } = people
  const { ChannelInstances } = availableChannels || {}
  const selfChannel = ChannelInstances?.find(i => i.instance === instanceNumber && i.channel.slug === slug)
  const { dateOfTokenExp } = selfChannel || {}
  const today = new Date()
  const tokenExpirationDate = new Date(dateOfTokenExp)
  const tokenHasExpired = dateOfTokenExp && (tokenExpirationDate - today) < 0
  const reauthorizeButtonAvailable = ['ebay', ...amazonSlugs, 'facebook', 'google', 'walmart', 'etsy', 'shopify'] // channels where reauthorize button should be shown

  const [reauthorizeChannel, { loading: r_loading, error: r_error }] = useMutation(REAUTHORIZE_CHANNEL, {
    onCompleted: data => {
      const { reAuthorizeChannel } = data || {}
      const { url: authUrl } = reAuthorizeChannel || {}
      window.location.href = authUrl
    }
  })

  const humanizeReauthError = e => {
    const { message } = e || {}
    const rawError = message?.split(' - ')?.[1]
    const error = JSON.parse(rawError || '{}')?.results?.failed?.[0]?.message
    return error || `Something went wrong reauthorizing ${slug}.`
  }

  // Onboard data
  const { data: onboard_data } = useQuery(GET_ONBOARD_DATA, { fetchPolicy: 'no-cache' })
  const { OnboardData } = onboard_data || {}
  const { confettiWasShownIn } = OnboardData || {}
  const confettiId = `channelAuth-${slug}-${instanceNumber}`

  const { reward } = useReward(confettiId, 'confetti', confetti)

  useEffect(() => {
    if (!confettiWasShownIn) return
    if (confettiWasShownIn.includes(confettiId)) return // confetti was already shown here
    reward()
    if (confettiWasShownIn?.length > 0) {
      updateOnboardData({ variables: { input: { confettiWasShownIn: [confettiId, ...confettiWasShownIn] } } }) // add this place to the confetti shown list
    } else if (confettiWasShownIn && !confettiWasShownIn.includes(confettiId)) {
      updateOnboardData({ variables: { input: { confettiWasShownIn: [confettiId] } } }) // in case confettiWasShownIn array is empty
    }
    // eslint-disable-next-line
  }, [onboard_data])

  // Update onboard data
  const [updateOnboardData] = useMutation(UPDATE_ONBOARD_DATA)

  return (
    <>
      <div className='d-flex justify-content-center w-100'>
        <span id={confettiId} />
      </div>
      <EmptyState
        isEmpty
        heading={tokenHasExpired ? 'Your token has expired' : 'You are ready to go!'}
        content={tokenHasExpired ? 'Please reauthorize this channel' : 'The authentication has been completed'}
        customImage={tokenHasExpired ? authentication : readyToGo}
      />
      <div className='d-flex justify-content-center mt-4'>
        {r_error && <ErrorAlert error={humanizeReauthError(r_error)} />}
      </div>
      <div className='d-flex justify-content-end mt-4'>
        {reauthorizeButtonAvailable.includes(slug) &&
          <ActionButton
            color='secondary'
            className='mr-3'
            loading={r_loading}
            onClick={() => reauthorizeChannel({ variables: { slug, instanceNumber } })}
          >
            Reauthorize {prettifySlug(slug)} {instanceNumber > 0 && instanceNumber}
          </ActionButton>}

        <Link to={`/channels/${slug}/${instanceNumber}/import`} className='btn btn-primary'>
          Import products
        </Link>
      </div>
    </>
  )
}

export { StepRedirectContent, StepFormContent, StepCompleteContent, StepOnlyInfoContent, StepFormSubmitContent }

debug('loaded')
