import React from 'react'
import { DownloaderCSV } from '../../../components'
import { useQuery } from '@apollo/react-hooks'
import { GET_WALMART_CATEGORIES } from './WalmartCategoryFilter'

const WalmartCategoriesDownloader = () => {
  const { loading: categoriesLoading, data: categoriesData } = useQuery(GET_WALMART_CATEGORIES, {
    variables: { search: 'getAll' }
  })
  const { WalmartCategories: matchedCategories } = categoriesData || {}
  const walmartCategoriesCsvData = matchedCategories?.length && matchedCategories?.map(({ name }) => [name])
  const csvForDownload = walmartCategoriesCsvData && [['Name'], ...walmartCategoriesCsvData]

  return (
    <div className='mb-3'>
      <DownloaderCSV
        data={csvForDownload || ''}
        disabled={!csvForDownload}
        text='Download all categories'
        className='mb-2'
        loading={categoriesLoading}
        filePrefix='Walmart Categories US -'
      />
      {!csvForDownload && <p className='text-muted mb-3'>There was a problem downloading the categories</p>}
    </div>
  )
}

export default WalmartCategoriesDownloader
