import React, { useState } from 'react'
import Debug from 'debug'
import { BigcommerceSync } from './BigcommerceSync'
import { Form, FormGroup } from 'reactstrap'
import { DeleteChannel } from './DeleteChannel'
import { useChannelSettings } from '../../hooks'
import { Loadable, ErrorAlert } from '../../components'
import ChannelSettingsItem from './ChannelSettingsItem'
import { SaveButton } from '../../components/SaveButton'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { UPDATE_CHANNEL_SETTINGS, GET_MAPPINGS_SPECIFIC } from './commonQueries'
import { DefaultShipMapping } from './DefaultShipMapping'
import { checkVisibility } from './utils'
import { channelsToShowLoadingText } from '.'
import { ShowRefreshedSettings } from './ShowRefreshedSettings'
const debug = Debug('sd:ChannelSettings')

export const ChannelSettings = ({ slug, instanceNumber, authStatus, isEnabled }) => {
  const [settings, { loading, error }, info] = useChannelSettings(slug, instanceNumber)
  const isAuthorized = authStatus === 'complete'

  const { data: mappingsData } = useQuery(GET_MAPPINGS_SPECIFIC, { variables: { slug, instanceNumber } })
  const { ChannelInstance: channelIns } = mappingsData || {}
  const { mappingsSpecific: amazonMappings } = channelIns || {}

  // Mutation stuff
  const [isDirty, setIsDirty] = useState(false)
  const [mutationError, setMutationError] = useState(false)
  const [updateChannelSettings, { loading: mutationLoading, data: mutationData }] = useMutation(UPDATE_CHANNEL_SETTINGS, {
    onCompleted: () => setIsDirty(false),
    onError: (err) => {
      debug('onError, e:', err)
      setMutationError(err || 'There was an error updating settings. Try again please.')
    }
  })

  // Updates newInfo when user changes any field
  const handleChangeValue = (event, field) => {
    setIsDirty(true)
    setMutationError(false)
    const newConfig = {
      name: field.name,
      value: event.name || event.value || '',
      modified: true
    }
    // Updates info with the new config
    info[field.generalIndex].value = newConfig
  }

  const onHandleSubmit = (e) => {
    e.preventDefault()
    // Get only updated fields
    const updatedFields = info
      .filter(info => info.value.modified)
      .map(i => i.value)
    // Delete modified flag
    updatedFields.map(i => delete i.modified)
    updateChannelSettings({ variables: { slug, instanceNumber, settings: updatedFields } })
  }

  const items = (info || []).filter(i => i.value && !(i.name === 'instance_label' && instanceNumber === 0) ? i : null)

  const channelsToShowDefaultShipMapping = ['amzn', 'amazon', 'ebay', 'walmart', 'etsy', 'shopify', 'bigcommerce', 'miva']

  return (
    <Loadable
      inline
      loading={loading}
      error={error}
      style={{ textAlign: 'center' }}
      loadingText={checkVisibility(slug, channelsToShowLoadingText) ? 'Please wait, we are importing your settings and refreshing fields' : null}
      content={
        <>
          <Form onSubmit={onHandleSubmit} className='channel-settings'>
            {slug === 'bigcommerce' && <BigcommerceSync slug={slug} instanceNumber={instanceNumber} field='categories' />}

            {isAuthorized && isEnabled && <ShowRefreshedSettings slug={slug} />}

            <FormGroup tag='fieldset'>
              {(items || []).map(item => <ChannelSettingsItem key={item.name} item={item} settings={settings} parentCallback={handleChangeValue} slug={slug} instanceNumber={instanceNumber} amazonMappings={amazonMappings} />)}
            </FormGroup>

            {mutationError && <ErrorAlert error={mutationError} />}

            <div className='d-flex justify-content-end'>
              <SaveButton
                type='submit'
                color='primary'
                disabled={!isDirty}
                loading={mutationLoading}
                success={mutationData ? 'true' : null}
                dirtysincelastsubmit={isDirty ? 'true' : null}
              >
                Save Settings
              </SaveButton>
            </div>
          </Form>

          {channelsToShowDefaultShipMapping.includes(slug) && (
            <>
              <hr className='my-4' />
              <DefaultShipMapping slug={slug} instanceNumber={parseInt(instanceNumber)} />
            </>
          )}

          <hr className='my-4' />
          <DeleteChannel slug={slug} instanceNumber={instanceNumber} />
        </>
      }
    />
  )
}
